<template>
  <div>
    <b-card>
      <h6 class="mb-3 font-weight-bold">Filter</h6>
      <b-row class="p-3">
        <b-col cols="3">
          <b-form-group
            label="Periode"
          >
            <date-picker
              placeholder="Select periode"
              v-model="periode"
              range
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Project type"
          >
            <custom-select
              v-model="filter.type"
              multiple
              placeholder="Select project type"
              :options="projectType"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Project name"
          >
            <custom-select
              v-model="filter.projectName"
              label="name"
              placeholder="Select project name"
              multiple
              :options="projectOption"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Client Name"
          >
            <custom-select
              v-model="filter.clientName"
              label="code"
              multiple
              placeholder="Select client name"
              :options="clientList"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="p-3">
        <b-col cols="3">
          <b-form-group
            label="Status"
          >
            <custom-select
              v-model="filter.statusTimeline"
              label="name"
              placeholder="Select status"
              multiple
              :options="statusOption"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-button
            class="merge reset mr-2 mt-4"
            variant="outline-warning"
            @click="resetFilter"
          >
            Reset Filter
          </b-button>
          <b-button
            class="next merge mr-3 mt-4"
            variant="warning"
            @click="changeFilter"
          >
            Apply Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" body-class="p-0">
      <div class="table-timeline">
        <GanttComponent
          ref="ganttTable"
          class="left-container"
          :tasks="tasks"
          :start-date="periode[0]"
          :end-date="periode[1]"
          :columns="columns"
          @task-double-click="taskDoubleClick"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import api from '@/api'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import { gantt } from 'dhtmlx-gantt'
import GanttComponent from '@/components/GanttComponent.vue'

export default {
  name: 'app',

  components: {
    DatePicker,
    GanttComponent
  },

  data() {
    return {
      tasks: {
        // Data yang di masukin ke ganttchart nya
        data: [],
        links: []
      },
      columns: [
        // Untuk Custom Header di Grid Kiri
        { name: 'text', label: 'Project', width: '*', tree: true },
        { name: 'start_date', label: 'Start', align: 'center' },
        { name: 'duration', label: 'Duration', align: 'center' },
        { name: 'add', label: '', width: 44 }
      ],
      clientList: [],
      projectOption: [],
      projectType: [
        'internal', 'external'
      ],
      periode: [],
      statusOption: [],
      filter: {
        type: '',
        projectName: '',
        clientName: '',
        statusTimeline: ''
      }
    }
  },

  computed: {
    stringifiedFilters() {
      let str = ''
      for (const key in this.filter) {
        if (this.filter[key].length === 1) {
          str += `,${key}==${this.filter[key]}`
        } else if (this.filter[key].length > 1) {
          str += `,${key}==${this.filter[key].join('|')}`
        }
      }
      return str
    }
  },

  created() {
    this.fetchDefaultDate()
    this.fetchTimelineProjects()
    this.fetchClientList()
    this.fetchProjectOption()
    this.fetchStatus()
  },

  methods: {
    async fetchTimelineProjects() {
      gantt.clearAll()

      const start = moment(this.periode[0]).format('YYYY-MM-DD')
      const end = moment(this.periode[1]).format('YYYY-MM-DD')

      const { data } = await api.timeline.byProjects({
        sorts: 'DevelopmentStart',
        filters: `developmentPeriod==${start};${end}${this.stringifiedFilters}`
      })
      data.map(v => {
        if (!v.text) {
          v.text = v.status
        }
      })

      this.tasks.data = data.filter(v => v.text)

      gantt.plugins({
        tooltip: true
      })

      gantt.templates.tooltip_text = (start, end, task) => {
        return `<h6>${task.detail.name ? task.detail.name : ''}</h6>
                <table>
                  <tr>
                    <td>
                      <div class="text-muted">Client Name</div>
                      <div style="margin-right:20px;">${task.detail.clientName ? task.detail.clientName : '-'}</div>
                      </br>
                    </td>
                    <td>
                      <div class="text-muted">Technology</div>
                      <div>${task.detail.technology ? task.detail.technology : '-'}</div>
                      </br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="text-muted">Project Code</div>
                      <div>${task.detail.code ? task.detail.code : '-'}</div>
                      </br>
                    </td>
                    <td>
                      <div class="text-muted">Status</div>
                      <div>${task.detail.status ? task.detail.status : '-'}</div>
                      </br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="text-muted">Sprint Quantity</div>
                      <div>${task.detail.sprintQuantity ? task.detail.sprintQuantity : '-'}</div>
                      </br>
                    </td>
                    <td>
                      <div class="text-muted">Current Sprint</div>
                      <div>${task.detail.currentSprint ? task.detail.currentSprint : '-'}</div>
                      </br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="text-muted">Periode Sprint</div>
                      <div>${task.detail.developmentPeriod ? task.detail.developmentPeriod : '-'}</div>
                      </br>
                    </td>
                    <td>
                      <div class="text-muted">Maintenance</div>
                      <div>${task.detail.maintenancePeriod ? task.detail.maintenancePeriod : '-'}</div>
                      </br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="text-muted">Team Leader</div>
                      <div>
                        ${task.detail.developmentTeams ? task.detail.developmentTeams.filter(v => v.isLeader).map(v => v.fullName) : '-'}
                      </div>
                      </br>
                    </td>
                    <td>
                      <div class="text-muted">Development Team</div>
                      <div>
                        ${task.detail.developmentTeams ? task.detail.developmentTeams.filter(v => !v.isLeader).map(v => v.fullName) : '-'}
                      </div>
                      </br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="text-muted">Holiday</div>
                      <div style="max-width:30px;">${task.detail.holidays ? task.detail.holidays : '-'}</div>
                    </td>
                  </tr>
                </table>`
      }

      gantt.config.start_date = this.periode[0]
      gantt.config.end_date = this.periode[1]
      gantt.init(this.$refs.ganttTable.$refs.ganttContainer)
      gantt.parse(this.tasks)
    },
    fetchDefaultDate() {
      const start = new Date()
      start.setDate(1)
      start.setMonth(start.getMonth() - 2)
      const end = new Date()
      end.setMonth(end.getMonth() + 2)
      end.setDate(0)
      this.periode.push(start)
      this.periode.push(end)
    },
    async fetchClientList() {
      const { data } = await api.client.list()
      this.clientList = data.data
    },
    async fetchStatus() {
      const { data } = await api.project.status()
      this.statusOption = data.data
    },
    async fetchProjectOption() {
      const { data } = await api.project.listProject()
      this.projectOption = data.data
    },
    taskDoubleClick(taskObj) {
    },
    resetFilter() {
      this.filter = this.$options.data().filter
      this.periode = this.$options.data().periode
      this.fetchDefaultDate()
      this.fetchTimelineProjects()
    },
    changeFilter() {
      if (!this.periode[0]) {
        this.periode = this.$options.data().periode
        this.fetchDefaultDate()
      }
      this.fetchTimelineProjects()
    }
  }
}

</script>

<style>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}

.row {
  margin-top: 0;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.table-timeline {
  height:50vh;
  width: 100%;
}
.left-container {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.gantt_cal_light{
  height: 325px !important;
}
.gantt_add{
  display: none !important;
}
.gantt_grid_head_add{
  display: none !important;
}
.gantt_tooltip {
  max-width: 800px;
}
.gantt_task_content {
  color: #000;
}
</style>
