var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h6',{staticClass:"mb-3 font-weight-bold"},[_vm._v("Filter")]),_c('b-row',{staticClass:"p-3"},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Periode"}},[_c('date-picker',{attrs:{"placeholder":"Select periode","range":""},model:{value:(_vm.periode),callback:function ($$v) {_vm.periode=$$v},expression:"periode"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Project type"}},[_c('custom-select',{attrs:{"multiple":"","placeholder":"Select project type","options":_vm.projectType},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Project name"}},[_c('custom-select',{attrs:{"label":"name","placeholder":"Select project name","multiple":"","options":_vm.projectOption,"reduce":function (ref) {
	var name = ref.name;

	return name;
}},model:{value:(_vm.filter.projectName),callback:function ($$v) {_vm.$set(_vm.filter, "projectName", $$v)},expression:"filter.projectName"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Client Name"}},[_c('custom-select',{attrs:{"label":"code","multiple":"","placeholder":"Select client name","options":_vm.clientList,"reduce":function (ref) {
	var name = ref.name;

	return name;
}},model:{value:(_vm.filter.clientName),callback:function ($$v) {_vm.$set(_vm.filter, "clientName", $$v)},expression:"filter.clientName"}})],1)],1)],1),_c('b-row',{staticClass:"p-3"},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Status"}},[_c('custom-select',{attrs:{"label":"name","placeholder":"Select status","multiple":"","options":_vm.statusOption,"reduce":function (ref) {
	var name = ref.name;

	return name;
}},model:{value:(_vm.filter.statusTimeline),callback:function ($$v) {_vm.$set(_vm.filter, "statusTimeline", $$v)},expression:"filter.statusTimeline"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"merge reset mr-2 mt-4",attrs:{"variant":"outline-warning"},on:{"click":_vm.resetFilter}},[_vm._v(" Reset Filter ")]),_c('b-button',{staticClass:"next merge mr-3 mt-4",attrs:{"variant":"warning"},on:{"click":_vm.changeFilter}},[_vm._v(" Apply Filter ")])],1)],1)],1),_c('b-card',{staticClass:"mt-3",attrs:{"body-class":"p-0"}},[_c('div',{staticClass:"table-timeline"},[_c('GanttComponent',{ref:"ganttTable",staticClass:"left-container",attrs:{"tasks":_vm.tasks,"start-date":_vm.periode[0],"end-date":_vm.periode[1],"columns":_vm.columns},on:{"task-double-click":_vm.taskDoubleClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }